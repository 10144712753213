/* eslint-disable */
import Vue from "vue";
import {
  Button,
  Menu,
  MenuItem,
  Submenu,
  Carousel,
  CarouselItem,
  Link
} from "element-ui";

Vue.use(Button)
  .use(Menu)
  .use(MenuItem)
  .use(Submenu)
  .use(Carousel)
  .use(CarouselItem)
  .use(Link);
