<template>
    <div id="app" @click="clickMain">

        <Head ref="head"></Head>
        <router-view />
        <Foot></Foot>
    </div>
</template>

<script>
import Head from './components/Head.vue';
import Foot from './components/Foot.vue';

export default {
    name: 'app',
    components: {
        Head, Foot
    },
    mounted() {
        if (this._isMobile()) {
            // alert("手机端");
            // this.$router.replace('/home_m');
            this.$store.commit("setDeviceType", 'm')
        } else {
            this.$store.commit("setDeviceType", 'pc')
        }
    },
    methods: {
        //App.vue
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        clickMain() {
            this.$refs.head.show = false
        }
    }
    // created() {
    //     this.$store.commit("setActiveIndex", '1')
    // }
};
</script>

<style>
@import './static/css/common.css';
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    position: relative;
    overflow: hidden;
}
.flex-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
</style>
