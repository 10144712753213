export default {
  computed: {
    deviceType() {
      return this.$store.state.deviceType
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    navTo(path, query) {
      this.$router.push({
        path,
        query
      });
    },
  }
};
