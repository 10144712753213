import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeIndex: "1",
    deviceType: "pc" // 'm' 移动端
  },
  mutations: {
    setActiveIndex(state, value) {
      state.activeIndex = value
    },
    setDeviceType(state, value) {
      state.deviceType = value
    }
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()]
});
