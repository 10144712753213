import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/home_m',
    name: 'home_m',
    component: () => import( /* webpackChunkName: "home_m" */ '../views/Home_m.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/case',
    name: 'Case',
    component: () => import( /* webpackChunkName: "case" */ '../views/Case.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import( /* webpackChunkName: "service" */ '../views/Service.vue')
  },
  {
    path: '/caseDetails',
    name: 'CaseDetails',
    component: () => import( /* webpackChunkName: "caseDetails" */ '../views/CaseDetails.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
