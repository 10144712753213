<template>
    <div>
        <div v-if="deviceType == 'pc'" class="head flex-row">
            <div class="logo flex-row"><span>社维思</span></div>
            <!-- <img src="../assets/logo.png" alt="" > -->
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" text-color="#666666" active-text-color="#409EFF" @select="handleSelect">
                <!-- <el-menu-item index="1">首页</el-menu-item>
                <el-menu-item index="2">产品服务</el-menu-item>
                <el-menu-item index="3">客户案例</el-menu-item>
                <el-menu-item index="4">关于我们</el-menu-item> -->
                <el-menu-item v-for="(item,index) in list" :index="(index+1)+''">{{item}}</el-menu-item>
            </el-menu>
        </div>
        <div v-else class="head_m flex-row">
            <div class="logo flex-row"><span>社维思</span></div>
            <strong>{{list[activeIndex-1]}}</strong>
            <img src="../assets/head.svg" alt="" @click.stop="show = !show">
        </div>
        <div v-show="show" class="head_box flex-column">
            <div v-for="(item,index) in list" :key="index+1" :class="activeIndex == index+1?'active':''" @click.stop="handleSelect(index+1)">{{item}}</div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'Head',
    data() {
        return {
            // activeIndex: '1'
            show: false,
            list: ["首页", "产品服务", "客户案例", "关于我们"]
        };
    },
    computed: {
        activeIndex: {
            get() {
                return this.$store.state.activeIndex
            },
            set(val) {
                this.$store.commit("setActiveIndex", val + '')
            }
        },
    },
    methods: {
        handleSelect(key) {
            this.show = false
            if (key == this.activeIndex) {
                return
            }
            if (key == 1) {
                this.navTo('/');
            }
            else if (key == 3) {
                this.navTo('/case');
            }
            else if (key == 2) {
                this.navTo('/service');
            }
            else if (key == 4) {
                this.navTo('/about');
            }
            this.activeIndex = key
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.el-menu.el-menu--horizontal {
    border: none;
}
.el-menu-item {
    font-size: 16px;
    padding: 0 !important;
    margin: 0 40px !important;
    letter-spacing: 5px;
}
.el-menu-item.is-active {
    font-weight: bold;
}
.head {
    position: fixed;
    width: 100vw;
    min-width: 1000px;
    overflow-x: hidden;
    // height: 80px;
    background: rgb(255, 255, 255);
    top: 0;
    z-index: 99999;
    .logo {
        width: 125px;
        height: 40px;
        background-image: url('../assets/logo.png');
        background-size: contain;
        background-repeat: no-repeat;
        font-size: 28px;
        justify-content: flex-end;
    }
}

.el-menu {
    background: initial;
}

.head_m {
    position: fixed;
    width: 100%;
    background: rgb(255, 255, 255);
    top: 0;
    z-index: 99999;
    overflow-x: hidden;
    justify-content: space-between;
    padding: 6px 0;
    align-items: center;
    // border-bottom: 1px solid #cccccc;
    .logo {
        width: 76px;
        // height: 60px;
        margin: 0 20px;
        background-image: url('../assets/logo.png');
        background-size: contain;
        background-repeat: no-repeat;
        font-size: 16px;
        justify-content: flex-end;
        align-items: center;
    }
    img {
        width: 20px;
        height: 20px;
        margin: 0 12px;
    }
    strong {
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 20px;
        transform: translate(-50%, -50%);
    }
}
.head_box {
    width: 160px;
    height: 200px;
    position: fixed;
    right: 5px;
    z-index: 99999;
    top: 65px;
    background: rgb(255, 255, 255);
    transition: all linear 0.5s;
    font-size: 16px;
    box-shadow: 0 4px 4px #cccccc;
    .active {
        color: #409EFF;
    }
}
</style>
