<template>
    <div v-if="deviceType == 'pc'" class="home">
        <!-- <img src="../assets/banner.png" alt="" > -->
        <div class="banner">
            <div class="banner-msg flex-column">
                <h3>社维思</h3>
                <h3>创新型互联网技术企业</h3>
                <h3>企业级数字化解决方案服务商</h3>
                <p>了解更多</p>
            </div>
            <el-carousel height="520px">
                <el-carousel-item v-for="(item,index) in bannerList" :key="'banner'+index">
                    <img :src="item" alt="">
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="module1 flex-column">
            <h1>可提供的服务</h1>
            <div class="module1-list flex-row">
                <div v-for="(item,index) in moduleList1" :key="'module1' + index" class="module1-item flex-column">
                    <img :src="item.img" alt="">
                    <h3>{{item.title}}</h3>
                    <p>{{item.content}}</p>
                    <!-- <el-link type="primary" class="link">了解更多》</el-link> -->
                </div>
            </div>
        </div>
        <div class="module2 flex-column">
            <h1>行业解决方案</h1>
            <div class="module2-list flex-row">
                <div v-for="(item,index) in moduleList2" :key="'module2' + index" class="module2-item flex-column" @click.stop="navTo('/case')">
                    <img :src="item.img" alt="">
                    <h3>{{item.title}}</h3>
                </div>
            </div>
        </div>
        <div class="module3 flex-column">
            <h1>精选案例</h1>
            <div class="module3-box flex-column">
                <div class="carousel-box">
                    <el-carousel height="400px" type="card" indicator-position="none">
                        <el-carousel-item v-for="(item,index) in moduleList3" :key="'module3'+index" class="module3-item" :style="{backgroundImage:'url('+item.img+')'}">
                            <div class="module3-msg flex-column">
                                <h3>{{item.title}}</h3>
                                <p>{{item.content}}</p>
                            </div>

                        </el-carousel-item>
                    </el-carousel>
                </div>

            </div>
        </div>
        <div class="module4 flex-column">
            <h1>合作伙伴</h1>
            <div class="module4-box flex-row">
                <div class="module4-item" v-for="(item,index) in partnerList" :key="'module4' + index ">
                    <img :src="item" alt="">
                </div>
            </div>
        </div>
    </div>
    <div v-else class="home-m">
        <div class="banner-m">
            <div class="banner-msg flex-column">
                <h3>社维思</h3>
                <h3>创新型互联网技术企业</h3>
                <h3>企业级数字化解决方案服务商</h3>
                <p>了解更多</p>
            </div>
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item,index) in bannerList" :key="index">
                    <img :src="item" alt="">
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="module-m flex-column">
            <h1>可提供的服务</h1>
            <div class="module-list flex-row">
                <div v-for="(item,index) in moduleList1" :key="'module1' + index" class="module1-item flex-column">
                    <img :src="item.img" alt="">
                    <h3>{{item.title}}</h3>
                    <p>{{item.content}}</p>
                    <!-- <el-link type="primary" class="link">了解更多》</el-link> -->
                </div>
            </div>
        </div>
        <div class="module-m flex-column">
            <h1>行业解决方案</h1>
            <div class="module-list flex-row">
                <div v-for="(item,index) in moduleList2" :key="'module2' + index" class="module2-item flex-column" @click.stop="navTo('/case')">
                    <img :src="item.img" alt="">
                    <h3>{{item.title}}</h3>
                </div>
            </div>
        </div>
        <div class="module-m flex-column">
            <h1>精选案例</h1>
            <div class="module-box flex-row">
                <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" style="width:100%">
                    <van-swipe-item v-for="(item,index) in moduleList3" :key="'module3'+index" class="module3-item" :style="{backgroundImage:'url('+item.img+')'}">
                        <div class="module3-msg flex-column">
                            <h3>{{item.title}}</h3>
                            <p>{{item.content}}</p>
                        </div>
                    </van-swipe-item>
                </van-swipe>
            </div>
        </div>
        <div class="module-m flex-column">
            <h1>合作伙伴</h1>
            <div class="module-box flex-row">
                <div class="module4-item" v-for="(item,index) in partnerList" :key="'module4' + index ">
                    <img :src="item" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Home',
    data() {
        return {
            bannerList: [
                require('../assets/banner01.jpg'),
                require('../assets/banner02.jpg'),
                require('../assets/banner03.png'),
                require('../assets/banner04.jpg')
            ],
            moduleList1: [
                {
                    img: require('../assets/service05.jpg'),
                    title: '软件开发',
                    content: '重点行业应用开发(SaaS、PaaS、CRM、HCM、银行核心系统、监管报送平台、系统搭建、人工智能助理)、大数据平台开发、商业智能、App开发、ERP、云平台、智能终端、产品化解决方案。'
                },
                {
                    img: require('../assets/service06.jpg'),
                    title: '测试',
                    content: '软件产品测试、应用软件测试、测试平台及产品、测试解决方案。'
                },
                {
                    img: require('../assets/service07.jpg'),
                    title: '运维',
                    content: '数据库维护(SQL Server、Oracle、MySQL)、操作系统维护(Windows、Linux、Unix等常用系统)、服务器硬件设备维护、网络设备维护、运维管理平台。'
                },
                {
                    img: require('../assets/service08.jpg'),
                    title: '运营服务',
                    content: 'IT咨询、IT服务、业务流程外包(BPO)、云/基础设施的管理、线上营销、数据采集与标注、内容管理和营销、设计服务、本地化、智能客服、大数据分析。'
                },
            ],
            moduleList2: [
                {
                    img: require('../assets/programme01.jpg'),
                    title: '智慧教育',
                },
                {
                    img: require('../assets/programme02.jpg'),
                    title: '电商+新零售'
                },
                {
                    img: require('../assets/programme03.jpg'),
                    title: '智慧社区'
                },
                {
                    img: require('../assets/programme04.jpg'),
                    title: '健康医疗'
                },
                {
                    img: require('../assets/programme05.jpg'),
                    title: 'O2O'
                },
                {
                    img: require('../assets/programme06.jpg'),
                    title: '智慧旅游'
                }
            ],
            moduleList3: [
                {
                    img: require('../assets/home_case01.jpg'),
                    title: '物联网',
                    content: "为全国领先的智慧安防设备应用商打造智慧管理系统。"
                },
                {
                    img: require('../assets/home_case02.jpg'),
                    title: '高科技',
                    content: "为国内最大的出行平台打造内部移动门户APP"
                },
                {
                    img: require('../assets/home_case03.jpg'),
                    title: '互联网',
                    content: "为全球最大的电脑软件提供商打在敏捷型端到端本地化服务"
                }
            ],
            partnerList: [
                require('../assets/partner01.jpg'),
                require('../assets/partner02.jpg'),
                require('../assets/partner03.jpg'),
                require('../assets/partner04.jpg'),
                require('../assets/partner05.jpg'),
                require('../assets/partner06.jpg'),
                require('../assets/partner07.png'),
                require('../assets/partner08.png'),
                require('../assets/partner09.png'),
                require('../assets/partner10.png'),
                require('../assets/partner11.png'),
                require('../assets/partner12.png'),
                require('../assets/partner13.png'),
                require('../assets/partner14.png'),
                require('../assets/partner15.png'),

            ]
        };
    },
    methods: {
    }
};
</script>
<style lang="less" scoped>
// pc端
.home {
    padding: 80px 0;
    min-width: 1000px;
    overflow-x: hidden;
    .banner {
        width: 100vw;
        min-width: 1000px;
        height: 520px;
        position: relative;
        overflow: hidden;
        img {
            width: 100vw;
            min-width: 1000px;
            height: 520px;
            object-fit: cover;
        }
        .banner-msg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            height: 600px;
            color: rgb(255, 255, 255);
            justify-content: center;
            align-items: flex-start;
            font-size: 36px;
            padding-left: 200px;
            h3 {
                margin: 8px 0;
            }
            p {
                font-size: 24px;
                margin: 32px 0;
                padding: 4px;
                border: 4px solid rgb(255, 255, 255);
            }
        }
    }

    h1 {
        font-size: 42px;
        margin-bottom: 40px;
        font-weight: bolder;
    }
    .module1 {
        margin: 120px 0;
        .module1-item {
            width: 300px;
            height: 500px;
            font-size: 14px;
            margin: 0 16px;
            overflow: hidden;
            justify-content: start;
            align-items: flex-start;
            border: 1px solid #cccccc;
            img {
                width: 300px;
                height: 240px;
                object-fit: cover;
            }
            h3 {
                margin: 48px 20px 16px 20px;
                font-size: 28px;
                font-weight: bolder;
            }
            p {
                padding: 0 20px;
                text-align: start;
                line-height: 20px;
                letter-spacing: 2px;
            }
            .link {
                margin: 24px 0 24px 16px;
            }
        }
    }
    .module2 {
        margin: 120px 0;
        .module2-item {
            width: 200px;
            height: 300px;
            cursor: pointer;
            font-size: 14px;
            overflow: hidden;
            align-items: flex-start;
            justify-content: center;
            img {
                width: 180px;
                height: 180px;
                object-fit: cover;
            }
            h3 {
                margin: 20px 0 0 0;
                font-size: 24px;
            }
        }
    }
    .module3 {
        margin: 120px 0;
        .module3-box {
            width: 100%;
            height: 400px;
            background: #2d343a;
            .carousel-box {
                width: 1000px;
                height: 400px;
                .module3-item {
                    width: 500px;
                    height: 400px;
                    font-size: 14px;
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    color: rgb(255, 255, 255);
                    .module3-msg {
                        height: 400px;
                        background: rgba(0, 0, 0, 0.65);
                        h3 {
                            margin-left: 16px;
                            font-size: 28px;
                        }
                    }
                }
            }
        }
    }
    .module4 {
        margin: 120px 0;
        .module4-box {
            width: 1200px;
            flex-wrap: wrap;
            .module4-item {
                width: 200px;
                height: 80px;
                margin: 20px 20px;
                box-shadow: 6px 4px 4px #cccccc;
                img {
                    width: 200px;
                    height: 80px;
                    object-fit: contain;
                }
            }
        }
    }
}
//移动端
.home-m {
    width: 100%;
    padding: 60px 0;
    h1 {
        font-size: 24px;
        margin: 20px 0;
        font-weight: bolder;
    }
    h3 {
        font-size: 18px;
        margin: 8px 0;
    }
    p {
        font-size: 14px;
        text-align: start;
        text-indent: 28px;
    }
    .banner-m {
        width: 100%;
        height: 200px;
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
        .banner-msg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            color: rgb(255, 255, 255);
            justify-content: center;
            align-items: flex-start;
            height: 200px;
            padding-left: 24px;
            h3 {
                margin: 8px 0;
            }
            p {
                font-size: 16px;
                margin: 12px 0;
                padding: 4px;
                border: 2px solid rgb(255, 255, 255);
            }
        }
    }
    .module-m {
        width: 100%;
        .module-list {
            flex-wrap: wrap;
            .module1-item {
                width: 150px;
                height: 400px;
                overflow: hidden;
                justify-content: start;
                // align-items: flex-start;
                // border: 1px solid #cccccc;
                margin: 0 0 16px 0;
                img {
                    width: 150px;
                    height: 150px;
                    object-fit: cover;
                }
            }
            .module2-item {
                width: 150px;
                height: 200px;
                overflow: hidden;
                justify-content: start;
                // align-items: flex-start;
                // border: 1px solid #cccccc;
                margin: 0 0 16px 0;
                img {
                    width: 150px;
                    height: 150px;
                    object-fit: cover;
                }
            }
        }
        .module-box {
            width: 100%;
            flex-wrap: wrap;
            .module3-item {
                width: 100%;
                height: 200px;
                overflow: hidden;
                justify-content: start;
                color: rgb(255, 255, 255);
                .module3-msg {
                    height: 200px;
                    background: rgba(0, 0, 0, 0.65);
                    h3 {
                        font-size: 24px;
                    }
                    p {
                        text-align: center;
                        text-indent: 0;
                    }
                }
            }
            .module4-item {
                width: 120px;
                height: 60px;
                margin: 12px 0;
                box-shadow: 6px 4px 4px #cccccc;
                img {
                    width: 120px;
                    height: 60px;
                    object-fit: contain;
                }
            }
        }
    }
}
</style>
