<template>
    <div class="flex-column" :class="deviceType == 'pc'?'foot':'foot-m'">
        <h3>专业咨询 了解更多</h3>
        <h3>更多解决方案，寻找专业团队，请联系我们</h3>
        <h3>13608194505</h3>
        <p>© 2020成都社维思网络有限公司 蜀ICP备16029460号-1</p>

    </div>
</template>

<script>
export default {

};
</script>

<style lang="less" scoped>
.foot {
    width: 100vw;
    min-width: 600px;
    overflow-x: hidden;
    height: 280px;
    background: #2d343a;
    color: silver;
    h3 {
        font-size: 28px;
        color: rgb(255, 255, 255);
    }
}
.foot-m {
    width: 100%;
    height: 80px;
    background: #2d343a;
    color: silver;
    font-size: 12px;
    h3 {
        color: rgb(255, 255, 255);
    }
}
</style>